import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

export default function dzienJednosci2019(props) {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{display: 'flex', maxWidth: 'unset'}}>
          <img src={'/communitiesSlides/effatha-rozaniec-X-2019.png'} alt={'effatha-rozaniec-X-2019'} style={{padding: '30px', margin: '10vw auto', width: '100%'}} />
        </div>
      </div>
    </Layout>
  );
}
